/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}
.font-weight-strong {
  font-weight: 600 !important;
}
.text-decoration-underline {
  text-decoration: underline;
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    margin-top: 15px;
    margin-bottom: 7px;
    border: 0;
    width: 70%;
    img {
      max-width: 50px;
      max-height: 50px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1em;
      font-weight: 600;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
      position: relative;
      top: -4px;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      margin-top: 0;
      font-size: 0.9em;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
      color: $theme-primary;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: $theme-primary;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  .dropdown-list {
    clear: right;
    max-width: 300px;
    z-index: 100;
  }
}
.cta-wrap {
  background: #3f4457;
  color: #fff;
}
#banner-wrap {
  background-image: linear-gradient(#fefefe, #e6eaef);
  background-size: cover;
}
.dropdown-list {
  width: 100%;
  max-width: 300px;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo span.big {
    font-size: 1.2em;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    width: 80%;
    max-width: 700px;
    img {
      max-width: 80px;
      max-height: 80px;
    }
    span.big {
      font-size: 1.9em;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header-wrap {
    border-top: 3px solid $theme-secondary;
  }
  #header #support {
    display: block;
  }
  #banner-wrap {
    background-size: contain;
  }
  #banner {
    background: url(../images/banner-person.png) no-repeat;
    background-position: 10px 0;
    .details {
      padding-left: 200px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
